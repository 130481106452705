import React, { useEffect, useState } from "react"
import "./eventActivities.scss"
import {
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import EventActivityList from "../../constants/eventActivityList"
import { t } from "i18next"
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons"
import { func } from "prop-types"

const EventActivities = () => {
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))

  const [expandedYearList, setExpandedYearList] = useState([])
  const [events, setEvents] = useState([])
  const [selectedYearIdx, setSelectedYearIdx] = useState(0)
  const [selectedMonthIdx, setSelectedMonthIdx] = useState(0)

  function tableExpand(params) {
    let tempArray = [...expandedYearList]
    const indexCell = tempArray.indexOf(params)
    if (indexCell == -1) {
      tempArray.push(params)
    } else {
      tempArray = tempArray.filter(e => e != params)
    }
    setExpandedYearList(tempArray)
  }

  function mappingEvents() {
    const tempEvents = []

    EventActivityList.map((e, i) => {
      e.months.map((el, idx) => {
        tempEvents.push(el.events)
      })
    })
    setEvents(tempEvents.flat())
  }

  function handleYearSelect(ev) {
    setSelectedYearIdx(ev.target.value)
    setSelectedMonthIdx(0)
  }

  function handleMonthSelect(ev) {
    setSelectedMonthIdx(ev.target.value)
  }

  useEffect(() => {
    mappingEvents()
  }, [])

  return (
    <div className="event-activities-collapse">
      <h1>{t(`about-us.event-and-activities`)}</h1>
      {!isMobile ? (
        <div className="desktop-table">
          <Table>
            <TableHead>
              <TableCell align="center">{t(`about-us.year`)}</TableCell>
              <TableCell align="center">{t(`about-us.month`)}</TableCell>
              <TableCell align="center">{t(`about-us.date`)}</TableCell>
              <TableCell align="center" className="cell-event-name">
                {t(`about-us.event-name`)}
              </TableCell>
              <TableCell align="center" className="cell-event-location">
                {t(`about-us.location`)}
              </TableCell>
              <TableCell align="center">{t(`about-us.partnership`)}</TableCell>
            </TableHead>
            <TableBody>
              {EventActivityList.map((e, i) => (
                <>
                  <TableRow>
                    <TableCell style={{ textAlign: "center", margin: "auto" }}>
                      <span
                        aria-hidden="true"
                        className="more"
                        onClick={ev => {
                          tableExpand(e.year)
                        }}
                      >
                        {expandedYearList.includes(e.year) ? (
                          <KeyboardArrowUp
                            style={{
                              color: "white",
                              marginRight: "5px",
                              background: "#000000",
                              borderRadius: "50%",
                            }}
                            onMouseOver={e =>
                              (e.currentTarget.style.color = "white")
                            }
                            onMouseOut={e =>
                              (e.currentTarget.style.color = "#f28e1e")
                            }
                          />
                        ) : (
                          <KeyboardArrowDown
                            style={{
                              color: "white",
                              marginRight: "5px",
                              background: "#000000",
                              borderRadius: "50%",
                            }}
                            onMouseOver={e =>
                              (e.currentTarget.style.color = "#f28e1e")
                            }
                            onMouseOut={e =>
                              (e.currentTarget.style.color = "white")
                            }
                          />
                        )}
                        {e.year}
                      </span>
                    </TableCell>
                    <TableCell style={{ textAlign: "center", margin: "auto" }}>
                      {e.months[0].month}
                    </TableCell>
                    <TableCell style={{ textAlign: "center", margin: "auto" }}>
                      {e.months[0].events[0].date}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center", margin: "auto" }}
                      className="cell-event-name"
                    >
                      {e.months[0].events[0].eventName}
                    </TableCell>
                    <TableCell
                      style={{ textAlign: "center", margin: "auto" }}
                      className="cell-event-location"
                    >
                      {e.months[0].events[0].location}
                    </TableCell>
                    <TableCell style={{ textAlign: "center", margin: "auto" }}>
                      {e.months[0].events[0].partner}
                    </TableCell>
                  </TableRow>
                  {expandedYearList.includes(e.year) && (
                    <>
                      {e.months.map((el, idx) =>
                        events.map((item, index) =>
                          item.year === e.year && item.month === el.month ? (
                            <TableRow
                              className={`${index % 2 == 0 ? "bg-grey" : ""}`}
                            >
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                              >
                                {""}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                              >
                                {idx === 0 ? "" : index == 0 ? item.month : ""}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                              >
                                {item.date}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                                className="cell-event-name"
                              >
                                {item.eventName}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                                className="cell-event-location"
                              >
                                {item.location}
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", margin: "auto" }}
                              >
                                {item.partner}
                              </TableCell>
                            </TableRow>
                          ) : (
                            <></>
                          )
                        )
                      )}
                    </>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div className="event-card">
          <div className="year-select-div">
            <Select
              className="year-select"
              value={selectedYearIdx}
              disableUnderline
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={KeyboardArrowDown}
              onChange={handleYearSelect}
            >
              {EventActivityList.map((e, i) => (
                <MenuItem className="menu-item" value={i}>
                  {e.year}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="month-select-div">
            <Select
              className="month-select"
              value={selectedMonthIdx}
              disableUnderline
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              IconComponent={KeyboardArrowDown}
              onChange={handleMonthSelect}
            >
              {EventActivityList[selectedYearIdx].months.map((e, i) => (
                <MenuItem value={i} className="month-menu-item">
                  {e.month}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className="event-content">
            {EventActivityList[selectedYearIdx].months[
              selectedMonthIdx
            ].events.map((e, i) => (
              <div className="content" key={i}>
                <div className="content-number">{i + 1}.</div>
                <div className="content-desc">
                  <div className="label">{t(`about-us.event-name`)}</div>
                  <div className="desc">{e.eventName}</div>
                </div>
                <div className="content-desc">
                  <div className="label">{t(`about-us.location`)}</div>
                  <div className="desc">{e.location}</div>
                </div>
                <div className="content-desc">
                  <div className="label">{t(`about-us.date`)}</div>
                  <div className="desc">{e.date}</div>
                </div>
                <div className="content-desc">
                  <div className="label">{t(`about-us.partnership`)}</div>
                  <div className="desc">{e.partner}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default EventActivities
