/* eslint-disable jsx-a11y/media-has-caption */
import React from "react"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Link } from "react-scroll"
import Layout from "../../components/layout"
import Container from "@material-ui/core/Container"

import Seo from "../../components/seo"
import Bod from "../../components/AboutUs/Bod"
import BodPhone from "../../components/AboutUs/BodPhone"
import TableCollapse from "../../components/AboutUs/TableCollapse"

import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"
import FinanceAuditReport2021 from "../../assets/Finance_audit_report_2021.pdf"
import FinanceAuditReport2022 from "../../assets/Finance_audit_report_2022.pdf"
import FinanceAuditReport2023 from "../../assets/Finance_audit_report_2023.pdf"

import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"

import { EffectCoverflow, Pagination } from "swiper"

import "./about.scss"

import { Fade, Flip } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"
import {
  googleAnalyticsEvent,
  googleAnalyticsEventCategories,
} from "../../helpers/reactGAEvents"
import AboutUsBanner from "../../components/AboutUs/banner"
import AboutUsVision from "../../components/AboutUs/visionMission"
import AboutUsCoreValues from "../../components/AboutUs/coreValues"
import AboutUsCareer from "../../components/AboutUs/career"
import AboutUsRecentActivities from "../../components/AboutUs/recentActivities"
import EventActivities from "../../components/AboutUs/eventActivities"

const AboutUsPage = () => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"))

  const handleDocumentClick = platformName => {
    googleAnalyticsEvent.customEvent(
      googleAnalyticsEventCategories.cta_documents,
      `${googleAnalyticsEventCategories.cta_documents}${platformName}`,
      platformName
    )
  }

  return (
    <Layout>
      <Seo title="About Us" key={0} />
      <div className="about" key={1}>
        {!isMobileSmall ? (
          <div className="about-nav">
            <Container>
              <div className="container">
                <Link to="vision" offset={-75} className="about-link">
                  <span className="link-text">{t(`about-us.vision`)}</span>
                </Link>
                <Link
                  to="core-values-section"
                  offset={-75}
                  className="about-link"
                >
                  <span className="link-text">Core Value</span>
                </Link>
                <Link to="profile" offset={-75} className="about-link">
                  <span className="link-text">{t(`about-us.profile`)}</span>
                </Link>

                <Link to="event_activities" offset={-75} className="about-link">
                  <span className="link-text">
                    {t(`about-us.event-and-activities`)}
                  </span>
                </Link>
                <Link
                  to="financial_statement"
                  offset={-120}
                  className="about-link"
                >
                  <span>{t(`about-us.financial-statement`)}</span>
                </Link>
              </div>
            </Container>
          </div>
        ) : (
          <></>
        )}

        {/*About us Banner*/}
        <AboutUsBanner />
        {/*Vision*/}

        <section id="vision">
          <AboutUsVision />
        </section>

        {/*Core values Section */}
        <section id="core-values-section">
          <AboutUsCoreValues />
        </section>

        {/* Profile BOD */}
        <section id="profile">{isDesktop ? <Bod /> : <BodPhone />}</section>

        {/* Recent Activities */}
        <section id="recent-activities">
          <AboutUsRecentActivities />
        </section>

        {/* Events and Activities */}
        <section id="event_activities">
          <EventActivities />
        </section>

        {/* Asetku Career */}
        <section id="asetku-career">
          <AboutUsCareer />
        </section>

        {isMobile ? (
          <div>
            {/* Financial Statement Mobile */}
            <section id="financial_statement">
              <div className="financial-statement">
                <div className="background">
                  <Container>
                    <div className="section-title">
                      <Flip bottom>
                        <h1 className="title">
                          {t(`about-us.financial-statement`)}
                        </h1>
                      </Flip>
                    </div>

                    <div className="container">
                      <div className="audit-list">
                        <Fade>
                          <div className="audit">
                            <StaticImage
                              src="../../assets/images/icons/chart.png"
                              className="chart-icon"
                              alt=""
                            />
                            <div className="audit-title">
                              <span className="title">
                                {t(`about-us.audit-report`)}
                              </span>
                              <span className="title">
                                {t(`about-us.pt-name`)}
                              </span>
                            </div>
                            <a
                              href={FinanceAuditReport2023}
                              target="_blank"
                              rel="noreferrer"
                              className="audit-year"
                              onClick={handleDocumentClick(
                                "Financial report 2023"
                              )}
                            >
                              <span>2023</span>
                            </a>
                          </div>
                        </Fade>
                        <Fade delay={700}>
                          <div className="audit">
                            <StaticImage
                              src="../../assets/images/icons/chart.png"
                              className="chart-icon"
                              alt=""
                            />
                            <div className="audit-title">
                              <span className="title">
                                {t(`about-us.audit-report`)}
                              </span>
                              <span className="title">
                                {t(`about-us.pt-name`)}
                              </span>
                            </div>
                            <a
                              href={FinanceAuditReport2022}
                              target="_blank"
                              rel="noreferrer"
                              className="audit-year"
                              onClick={handleDocumentClick(
                                "Financial report 2022"
                              )}
                            >
                              <span>2022</span>
                            </a>
                          </div>
                        </Fade>
                        <Fade delay={1400}>
                          <div className="audit">
                            <StaticImage
                              src="../../assets/images/icons/chart.png"
                              className="chart-icon"
                              alt=""
                            />
                            <div className="audit-title">
                              <span className="title">
                                {t(`about-us.audit-report`)}
                              </span>
                              <span className="title">
                                {t(`about-us.pt-name`)}
                              </span>
                            </div>
                            <a
                              href={FinanceAuditReport2021}
                              target="_blank"
                              rel="noreferrer"
                              className="audit-year"
                              onClick={handleDocumentClick(
                                "Financial report 2021"
                              )}
                            >
                              <span>2021</span>
                            </a>
                          </div>
                        </Fade>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div>
            {/* Financial Statement Desktop*/}
            <section id="financial_statement">
              <div className="financial-statement">
                <div className="background">
                  <Container>
                    <div className="section-title">
                      <Flip bottom>
                        <h1 className="title">
                          {t(`about-us.financial-statement`)}
                        </h1>
                      </Flip>
                    </div>

                    <div style={{ position: "relative" }}>
                      <Swiper
                        slidesPerView={isDesktop ? 3 : 1}
                        coverflowEffect={{
                          rotate: 0,
                          stretch: -42,
                          scale: 1,
                          depth: 0,
                          modifier: 1.0,
                          slideShadows: false,
                        }}
                        modules={[EffectCoverflow, Pagination]}
                        scrollbar={{ draggable: true }}
                        onSwiper={swiper => console.log(swiper)}
                        onSlideChange={() => console.log("slide change")}
                      >
                        <SwiperSlide>
                          <div className="container">
                            <div className="audit-list-desktop">
                              <Flip right duration={1000}>
                                <div className="audit">
                                  <StaticImage
                                    src="../../assets/images/icons/chart.png"
                                    className="chart-icon"
                                    alt=""
                                  />
                                  <div className="audit-title">
                                    <span className="title">
                                      {t(`about-us.audit-report`)}
                                    </span>
                                    <span className="title">
                                      {t(`about-us.pt-name`)}
                                    </span>
                                  </div>
                                  <a
                                    href={FinanceAuditReport2023}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="audit-year"
                                  >
                                    <span>2023</span>
                                  </a>
                                </div>
                              </Flip>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="container">
                            <div className="audit-list-desktop">
                              <Flip right duration={1500} delay={500}>
                                <div className="audit">
                                  <StaticImage
                                    src="../../assets/images/icons/chart.png"
                                    className="chart-icon"
                                    alt=""
                                  />
                                  <div className="audit-title">
                                    <span className="title">
                                      {t(`about-us.audit-report`)}
                                    </span>
                                    <span className="title">
                                      {t(`about-us.pt-name`)}
                                    </span>
                                  </div>
                                  <a
                                    href={FinanceAuditReport2022}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="audit-year"
                                  >
                                    <span>2022</span>
                                  </a>
                                </div>
                              </Flip>
                            </div>
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="container">
                            <div className="audit-list-desktop">
                              <Flip right duration={1500} delay={1000}>
                                <div className="audit">
                                  <StaticImage
                                    src="../../assets/images/icons/chart.png"
                                    className="chart-icon"
                                    alt=""
                                  />
                                  <div className="audit-title">
                                    <span className="title">
                                      {t(`about-us.audit-report`)}
                                    </span>
                                    <span className="title">
                                      {t(`about-us.pt-name`)}
                                    </span>
                                  </div>
                                  <a
                                    href={FinanceAuditReport2021}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="audit-year"
                                  >
                                    <span>2021</span>
                                  </a>
                                </div>
                              </Flip>
                            </div>
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </Container>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default withTrans(AboutUsPage)
