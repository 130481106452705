const EventActivityList = [
  {
    year: "2018",
    months: [
      {
        month: "August",
        events: [
          {
            year: "2018",
            month: "August",
            date: "11 August",
            eventName:
              "Lenders Gathering Asetku Explore the World of Digital Financial Service",
            location: "Bengawan Solo, Jakarta",
            partner: "Non",
          },
          {
            year: "2018",
            month: "August",
            date: "20-25 August",
            eventName: "Focus Group Discussion (FGD) with Lenders Surabaya",
            location: "Non",
            partner: "Non",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2018",
            month: "September",
            date: "10-15 September",
            eventName: "Focus Group Discussion (FGD) with Lenders Medan",
            location: "Non",
            partner: "Non",
          },
          {
            year: "2018",
            month: "September",
            date: "10-16 September",
            eventName: "Focus Group Discussion (FGD) with Lenders Bandung",
            location: "Non",
            partner: "Non",
          },
        ],
      },

      {
        month: "December",
        events: [
          {
            year: "2018",
            month: "December",
            date: "15 December",
            eventName: "Challenges & Opportunities in Fintech P2P Lending",
            location: "Surabaya",
            partner: "Non",
          },
        ],
      },
    ],
  },
  {
    year: "2019",
    months: [
      {
        month: "January",
        events: [
          {
            year: "2019",
            month: "January",
            date: "30 January",
            eventName: "Konferensi Akulaku Sahabatku",
            location: "Patio Jakarta",
            partner: "Akulaku",
          },
        ],
      },
      {
        month: "February",
        events: [
          {
            year: "2019",
            month: "February",
            date: "11 February",
            eventName: "Konferensi Pers Asetku #JelasBerkembang",
            location: "Ritz Carlton Pasific Place, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "February",
            date: "20 February",
            eventName: "Fintech Talk Vol.2 By Aftech",
            location: "Satrio Tower, Jakarta",
            partner: "Aftech",
          },
          {
            year: "2019",
            month: "February",
            date: "23 February",
            eventName:
              "Gerakan Peningkatan Inklusi Keuangan Indonesia Bersama Asetku",
            location: "JW Marriott, Medan",
            partner: "Non",
          },
          {
            year: "2019",
            month: "February",
            date: "27 February",
            eventName: "Challenges and Opportunities in Fintech P2P Lending",
            location: "Binus University International, Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "March",
        events: [
          {
            year: "2019",
            month: "March",
            date: "8 March",
            eventName:
              "Accounting in Revolution 4.0 to Achieve SDGs 2030 at Accounting Fair 2019",
            location: "Universitas Bakrie, Jakarta",
            partner: "Non",
          },
          {
            year: "2019",
            month: "March",
            date: "8 March",
            eventName: "Stronger HIPMI 4.0 Powered by Asetku",
            location: "Marbella Hotel, Bandung",
            partner: "HIPMI",
          },
          {
            year: "2019",
            month: "March",
            date: "16-17 March",
            eventName: "Sosialisasi Fintech Asetku #JelasBerkembang",
            location: "Bintaro X Change Tangerang - Akulaku KarnavAL",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "March",
            date: "30 March",
            eventName:
              "How to Reach Your Financial Goal Faster in Digital Era bersama Finansialku",
            location: "Warunk Upnormal Tebet, Jakarta",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2019",
            month: "April",
            date: "10 April",
            eventName: "Jadi Millennial Cerdas di Era Digital Finance",
            location:
              "Gd. Aula Pasca Sarjana Universitas Diponegoro Jl. Hayam Wuruk Semarang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "13 April",
            eventName: "The Rise of Fintech",
            location: "Atmajaya University",
            partner: "Non",
          },
          {
            year: "2019",
            month: "April",
            date: "25 April",
            eventName: "Powering Indonesia SME's with Technology",
            location: "The Kasablanka - Main Hall. Jakarta",
            partner: "Mekari",
          },
        ],
      },
      {
        month: "May",
        events: [
          {
            year: "2019",
            month: "May",
            date: "2-3 May",
            eventName: "Fintech Days",
            location: "Novotel Hotel Palembang",
            partner: "AFPI",
          },
          {
            year: "2019",
            month: "May",
            date: "8-11 May",
            eventName: "Ramadan Bersama Akulaku #AkuBisaBerbagi",
            location: "Senayan City, Jakarta",
            partner: "Akulaku",
          },
          {
            year: "2019",
            month: "May",
            date: "24-26 May",
            eventName: "ICS (Indonesian Cashless Society) 2019",
            location: "Summarecon Mall Bekasi",
            partner: "Non",
          },
          {
            year: "2019",
            month: "May",
            date: "25 May",
            eventName: "Instagram 3.0 - Zero To Millions",
            location: "Hotel Ibis Style Gajah Mada",
            partner: "Rintisan",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2019",
            month: "June",
            date: "25 June",
            eventName: "CGS-CIMB 13th Annual Indonesia Conference",
            location: "@ Ayana MidPlaza",
            partner: "Non",
          },
          {
            year: "2019",
            month: "June",
            date: "27 June",
            eventName: "Bagaimana merencanakan keuangan & danai di era digital",
            location: "@ C3 Jogjakarta Community",
            partner: "Finansialku",
          },
        ],
      },
      {
        month: "July",
        events: [
          {
            year: "2019",
            month: "July",
            date: "19 July",
            eventName: "Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ DILo Coworking Space Padang",
            partner: "Non",
          },
          {
            year: "2019",
            month: "July",
            date: "26 July",
            eventName: "Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ Meet Up Coworking Space Pekanbaru",
            partner: "Non",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2019",
            month: "August",
            date: "2 August",
            eventName: "Fintech Fest 2019",
            location: "@ Four Points Manado",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "September",
        events: [
          {
            year: "2019",
            month: "September",
            date: "13 September",
            eventName:
              "Sosialisasi Jadi Pengguna Cerdas di Era Digital Finance",
            location: "@ Bali",
            partner: "Non",
          },
          {
            year: "2019",
            month: "September",
            date: "23-24 September",
            eventName: "Indonesia Fintech Submit & Expo 2019",
            location: "@ Jakarta Convention Center",
            partner: "Aftech",
          },
        ],
      },
      {
        month: "October",
        events: [
          {
            year: "2019",
            month: "October",
            date: "17-20 October",
            eventName: "Finexpo & Sundown Run 2019",
            location: "@ Kota Kasablanka",
            partner: "Non",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2019",
            month: "November",
            date: "6-8 November",
            eventName: "Indonesia Fintech Show 2019",
            location: "@ Jakarta Convention Center",
            partner: "Tarsus",
          },
          {
            year: "2019",
            month: "November",
            date: "29 November 2019 - 1 Desember 2019",
            eventName: "Fintech Exhibition Surabaya 219",
            location: "@Convension Hall, Pakuwon Trade Center",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2020",
    months: [
      {
        month: "March",
        events: [
          {
            year: "2020",
            month: "March",
            date: "March 10",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Gunung Jati, Cirebon",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "June",
        events: [
          {
            year: "2020",
            month: "June",
            date: "June 16",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Jambi, Jambi",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "July",
        events: [
          {
            year: "2020",
            month: "July",
            date: "July 9",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Tanjungpura, Pontianak",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2020",
            month: "August",
            date: "August 11",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Bengkulu, Bengkulu",
            partner: "AFPI",
          },
          {
            year: "2020",
            month: "August",
            date: "August 25",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Halu Oleo, Kendari",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "October",
        events: [
          {
            year: "2020",
            month: "October",
            date: "October 6",
            eventName: "AFPI Goes to Campus",
            location: "Universitas Mataram, Mataram",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "November",
        events: [
          {
            year: "2020",
            month: "November",
            date: "November 17",
            eventName:
              "Indonesian Fintech Facing Recession: Brutal Challange or Great Opportunity?",
            location: "Live via Youtube DailySocial",
            partner: "DailySocial",
          },
          {
            year: "2020",
            month: "November",
            date: "26 November",
            eventName: "AFPI Goes To Campus",
            location: "Bangka Belitung",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "December",
        events: [
          {
            year: "2020",
            month: "December",
            date: "3 Desember ",
            eventName:
              "AsetKu Literasi Event - Dunia P2P Lending dan Dunia Keuangan Setelah New Normal, Berbagai Kesempatan di Dalamnya",
            location: "Jambi",
            partner: "non",
          },
        ],
      },
    ],
  },
  {
    year: "2021",
    months: [
      {
        month: "February",
        events: [
          {
            year: "2021",
            month: "February",
            date: "24 Februari",
            eventName: "AFPI Goes To Campus",
            location: "Lampung",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "February",
            date: "26 Februari",
            eventName: "AFPI Goes To Campus",
            location: "Bengkulu",
            partner: "AFPI",
          },
        ],
      },

      {
        month: "April",
        events: [
          {
            year: "2021",
            month: "April",
            date: "30 April",
            eventName: "AFPI Goes To Campus",
            location: "Batam",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "May",
        events: [
          {
            year: "2021",
            month: "May",
            date: "28 Mei",
            eventName: "AFPI Goes To Campus",
            location: "Ambon",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "May",
            date: "31 Mei",
            eventName: "AFPI Goes To Campus",
            location: "Salatiga",
            partner: "AFPI",
          },
          {
            year: "2021",
            month: "May",
            date: "",
            eventName: "CSR 1 Project Month of Eid Mubarak 2021 | Mei 2021",
            location: "Jakarta",
            partner: "Non",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2021",
            month: "June",
            date: "30 Juni",
            eventName: "AFPI Goes To Campus",
            location: "Jember",
            partner: "AFPI",
          },
        ],
      },
    ],
  },
  {
    year: "2022",
    months: [
      {
        month: "January",
        events: [
          {
            year: "2022",
            month: "January",
            date: "6 January",
            eventName:
              "Program Beasiswa NusantaraKU - Kegiatan Amal Terangi Jalan Menuju Sekolah",
            location:
              "Nusa Tenggara Barat (NTB), Nusa Tenggara Timur (NTT) dan papua",
            partner: "Runcing Foundation",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2022",
            month: "June",
            date: "24 Juni",
            eventName: "Fintech Lending Days",
            location: "Makassar",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "August",
        events: [
          {
            year: "2022",
            month: "August",
            date: "8 Agustus",
            eventName:
              "Sosialisasi Edukasi dan Literasi Keuangan “Generasi Muda Semua Bisa” ",
            location: "Medan",
            partner: "Akulaku Group",
          },
        ],
      },
    ],
  },
  {
    year: "2023",
    months: [
      {
        month: "March",
        events: [
          {
            year: "2023",
            month: "March",
            date: "14 Maret",
            eventName: "AFPI Goes To Campus",
            location: "Samarinda",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "April",
        events: [
          {
            year: "2023",
            month: "April",
            date: "11 April",
            eventName: "AFPI Goes To Campus",
            location: "Semarang",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "October",
        events: [
          {
            year: "2023",
            month: "October",
            date: "28 Oktober",
            eventName:
              "CSR “A Year Older, A Forest Richer: AsetKu 1000 Mangrove Tree Planting” ",
            location: "Pulau Seribu",
            partner: "Lindungi Hutan",
          },
        ],
      },
    ],
  },
  {
    year: "2024",
    months: [
      {
        month: "May",
        events: [
          {
            year: "2024",
            month: "May",
            date: "6-7 Mei",
            eventName: "Fintech Lending Days",
            location: "Medan",
            partner: "AFPI",
          },
        ],
      },
      {
        month: "June",
        events: [
          {
            year: "2024",
            month: "June",
            date: "27 Juni",
            eventName:
              "Bincang Bareng AsetKu 'Bijak Memanfaatkan Fasilitas Keuangan Digital'",
            location: "Bangka Belitung",
            partner: "HIPMI Pangkal Pinang",
          },
        ],
      },
    ],
  },
]
export default EventActivityList
