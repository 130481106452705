import { Modal, useMediaQuery, useTheme } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import { t } from "i18next"
import React, { useEffect, useRef, useState } from "react"
import "./recentActivities.scss"
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"

import { EffectCoverflow, Navigation, Pagination } from "swiper"

const AboutUsRecentActivities = () => {
  const sliderRef = useRef(null)
  const isMobileSmall = useMediaQuery(useTheme().breakpoints.down("sm"))
  const [openImgView, setOpenImgView] = useState(false)
  const [imgDataToView, setImgDataToView] = useState("")

  function handleImgViewClick(isOpen, imgData) {
    setImgDataToView(imgData)
    setOpenImgView(isOpen)
  }

  return (
    <div className="recent-activities">
      <div className="title-desc">
        <div className="title">{t("about-us.our-recent-activities")}</div>
        <div className="desc">
          {t("about-us.our-recent-activities-description")}
        </div>
      </div>
      {isMobileSmall ? (
        <Swiper
          onBeforeInit={swiper => {
            sliderRef.current = swiper
          }}
          slidesPerView={1}
          coverflowEffect={{
            rotate: 0,
            stretch: -42,
            scale: 1,
            depth: 0,
            modifier: 1.0,
            slideShadows: false,
          }}
          centeredSlides
          modules={[EffectCoverflow, Pagination]}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            type: "bullets",
          }}
          scrollbar={{ draggable: true }}
          className="mobile-img-slider"
        >
          {masonryData.flat().map((e, i) => (
            <SwiperSlide key={i}>
              {e.isVideo ? (
                <GalleryVideo
                  image={e.thumbnail}
                  video={e.src}
                  isYoutube={e.isYoutube}
                  isMobileSmall={true}
                />
              ) : (
                <div
                  className="img-container-mobile"
                  key={i}
                  onClick={() => {
                    handleImgViewClick(true, e.src)
                  }}
                >
                  <img src={e.src} />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="activities">
          <div
            className="swipper-pagination-button"
            onClick={() => sliderRef.current?.slidePrev()}
          >
            <StaticImage
              src="../../assets/images/icons/arrow-gallery-left.png"
              className="nav-icon"
              alt=""
            />
          </div>

          <Swiper
            onBeforeInit={swiper => {
              sliderRef.current = swiper
            }}
            slidesPerView={1}
            coverflowEffect={{
              rotate: 0,
              stretch: -42,
              scale: 1,
              depth: 0,
              modifier: 1.0,
              slideShadows: false,
            }}
            centeredSlides
            modules={[EffectCoverflow, Navigation]}
            pagination={{
              clickable: true,
            }}
            scrollbar={{ draggable: true }}
          >
            {masonryData.map((el, idx) => (
              <SwiperSlide key={idx}>
                <div className="masonry">
                  {el.map((e, i) => {
                    return e.isVideo ? (
                      <GalleryVideo
                        image={e.thumbnail}
                        video={e.src}
                        isYoutube={e.isYoutube}
                      />
                    ) : (
                      <div
                        className="img-container"
                        key={i}
                        style={{ backgroundImage: `url(${e.src})` }}
                        onClick={() => {
                          handleImgViewClick(true, e.src)
                        }}
                      />
                    )
                  })}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className="swipper-pagination-button"
            onClick={() => sliderRef.current?.slideNext()}
          >
            <StaticImage
              src="../../assets/images/icons/arrow-gallery-right.png"
              className="nav-icon"
              alt=""
            />
          </div>
        </div>
      )}

      <Modal
        open={openImgView}
        onClose={() => {
          handleImgViewClick(false, "")
        }}
        className="img-modal"
      >
        <div className="container">
          <img src={imgDataToView} />
          <div
            className="button-close"
            onClick={() => {
              handleImgViewClick(false, "")
            }}
          >
            {t("general.close")}
          </div>
        </div>
      </Modal>
    </div>
  )
}

function GalleryVideo({ image, video, isYoutube, isMobileSmall }) {
  const [openVideo, setOpenVideo] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const handleOpenVideo = () => {
    setOpenVideo(true)
  }

  const handleCloseVideo = () => {
    setOpenVideo(false)
  }

  return (
    <div className={`gallery${isMobileSmall ? "-mobile" : ""}`}>
      <div className="play-button">
        <div
          type="button"
          onClick={handleOpenVideo}
          onKeyDown={handleOpenVideo}
          aria-hidden="true"
          onMouseOver={() => {
            setIsHover(true)
          }}
          onFocus={() => {
            setIsHover(true)
          }}
          onMouseOut={() => {
            setIsHover(false)
          }}
          onBlur={() => {
            setIsHover(false)
          }}
        >
          {isHover ? (
            <StaticImage
              src="../../assets/images/icons/play_1.png"
              className="play-icon"
              alt="button"
            />
          ) : (
            <StaticImage
              src="../../assets/images/icons/play_2.png"
              className="play-icon"
              alt="button"
            />
          )}
        </div>
      </div>
      <img src={image} className="gallery-img" alt="" />
      <Modal open={openVideo} onClose={handleCloseVideo}>
        <div className="video-modal">
          {isYoutube ? (
            <iframe
              src={video}
              frameborder="0"
              className="video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          ) : (
            <video
              src={video}
              autoPlay={true}
              className="video"
              controls={true}
            ></video>
          )}
        </div>
      </Modal>
    </div>
  )
}

const masonryData = [
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/26NvmNDq3MIjaZITRzGN4y8wkOcYaXm3D4rmyR5mVew.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/gWxJLlInjA3VHFn7XH8aG0VYYAyGTp374SxrokMSvSo.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src: "https://www.youtube.com/embed/8v7gTUVtU9s?si=xA9KKw3sc793RsEn",
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/bOxt63dxd5gBJCI8NdTFU00bnY92AliLZXirqho3DXQ.jpg",
      isVideo: true,
      isYoutube: true,
    },
    {
      src: "https://www.youtube.com/embed/J654JBX1BWw?si=lYYA8fBuKmpms5dT",

      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/SO7BWcyWEfO7gxnjmT9YFb7PUSYBuvA0-hdA1D241Gs.jpg",
      isVideo: true,
      isYoutube: true,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/GKBlGBmCsEMM9rlHKIWqW0CLYRUqha0Im32k58FF5v0.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/IuUqpJIZ0vpA4sgNTGERbCeCmiKgIoPqJyyg0LLt0ss.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src: "https://www.youtube.com/embed/42wwaNQwG54?si=-MMbkHA42fXpDkhV",

      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/q4hWEM2voO7r6AsG1rnT-Xu_qGI2vD2vHizcs41UqMI.jpg",
      isVideo: true,
      isYoutube: true,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/4_Team%20IT.mp4",
      isYoutube: false,
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/wHkuC5ZkjlajEdQOJ96nBu0IWQIOOI0K_VtWlYFmHqw.jpg",
      isVideo: true,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/1_FintechDayMakasar1.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/2_Bukber1.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/2_Bukber2.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/3_OJK%20Yogya1.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/5-93t9Ljfdru-UrXUt-pywA_K6seo_ERWEDCEMCAQ5E.mp4",
      isYoutube: false,
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/SiGZLMu_-Ov3WOHpQfOzGucXZ63jrVOqlxPUSOTQkb4.jpg",
      isVideo: true,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/5_17an1.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/5_17an3.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/6_Yoga2.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/1_FintechDayMakasar2.png",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/coTJgFI6vsrLxwxl9LJP3kmdC0992LRh6eQjnPbyqEs.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/10_eye%20checking.mp4",
      isYoutube: false,
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/10_CoverVideoeyechecking.png",
      isVideo: true,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/AujKYLi4FWUAHlKve77QUS76EjOnRB8wUWY7CNyXo8U.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/yKiBYgQ9cXqKIjt2dGUXjdGQyVLM0Qt-JxrLMH4dEa0.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/SboK5GGxCU6RAjrEFH0AGRSIsMSPBJzZa6Kv2DFaG7Q.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/HEMKVb05Uj5WncYdTAfpuwGHgxyiPtYylyQ995lbcxw.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },

    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/8_Body%20Combat%20.mp4",
      isYoutube: false,
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/8_CoverVideoBody%20Combat.png",
      isVideo: true,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/Me1aV4PojGq4qmZG0R5yrn89PGZoTeuklhCccZHPnZg.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
  ],
  [
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/5dPKNlxhbmWYtNrPkURatVlnP4xO-3Jk-S0Q7lawKLo.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/0JAH7QeYXMe_P5KLeTPb-0ErFc7zjanF12BYo1uIjqc.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/TC76dVzRQiykXOhz4hA7YNeytVN89RWRlUkB2gV44OQ.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/-19FP23qEWit8PcYU7Y9VZG-nkko_5ENted8w-TGbd0.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/9_Akulaku%20Medan.mp4",
      isYoutube: false,
      thumbnail:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/asetku/home/9_CoverVideoAkulakuMedan.png",
      isVideo: true,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/jvPQLk9wmxPvYttQICXwX4WFkVc0tcuwgIs54Qj8Ylg.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
    {
      src:
        "https://line4-asetku-prod.oss-ap-southeast-5.aliyuncs.com/public/read/finc/yS_iWnU81zJbPxWYnmOO8pppEeAjlpg4UyWMGw60pBk.jpg",
      isYoutube: false,
      thumbnail: "",
      isVideo: false,
    },
  ],
]

export default AboutUsRecentActivities
